@keyframes runner {
	12% {
		background: transparent url("./plague_move_0.png") 0 0 no-repeat;
	}
	24% {
		background: transparent url("./plague_move_1.png") 0 0 no-repeat;
	}
	36% {
		background: transparent url("./plague_move_2.png") 0 0 no-repeat;
	}
	48% {
		background: transparent url("./plague_move_3.png") 0 0 no-repeat;
	}
	60% {
		background: transparent url("./plague_move_4.png") 0 0 no-repeat;
	}
	72% {
		background: transparent url("./plague_move_5.png") 0 0 no-repeat;
	}

	84% {
		background: transparent url("./plague_move_6.png") 0 0 no-repeat;
	}
	96% {
		background: transparent url("./plague_move_7.png") 0 0 no-repeat;
	}
}
@keyframes weapon {
	12% {
		top: -64px;
	}
	24% {
		top: -66px;
	}
	36% {
		top: -68px;
	}
	48% {
		top: -66px;
	}
	60% {
		top: -64px;
	}
	72% {
		top: -62px;
	}

	84% {
		top: -60px;
	}
	96% {
		top: -62px;
	}
}
@keyframes hands {
	12% {
		top: -94px;
	}
	24% {
		top: -96px;
	}
	36% {
		top: -98px;
	}
	48% {
		top: -96px;
	}
	60% {
		top: -94px;
	}
	72% {
		top: -92px;
	}

	84% {
		top: -90px;
	}
	96% {
		top: -92px;
	}
}

#loading-page {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000000;
	color: white;
	flex-direction: column;
}

#loading-page.failed-loading .loading-gun, #loading-page.failed-loading .loading-hands, #loading-page.failed-loading .loading-runner {
	animation: none;
}

#loading-page.failed-loading .loading-runner {
	background: transparent url("./plague_idle_0.png") 0 0 no-repeat;
}

#loading-page p {
	position: relative;
	top: -30px;
	font-size: 24px;
	text-align: center;
}

.loading-gun {
	height: 32px;
	width: 64px;
	position: relative;
	top: -62px;
	left: 47px;
	transform: scale(2.5) rotate(25deg);
	background: transparent url("./bmg_factory.png") 0 0 no-repeat;
	animation: weapon 450ms infinite;
}

.loading-hands {
	height: 32px;
	width: 64px;
	position: relative;
	top: -92px;
	left: 47px;
	transform: scale(2.5) rotate(25deg);
	background: transparent url("./bmg_hands.png") 0 0 no-repeat;
	animation: hands 450ms infinite;
}

.loading-runner {
	height: 36px;
	width: 24px;
	position: relative;
	top: -75px;
	transform: scale(4);
	background: transparent url("./plague_move_0.png") 0 0 no-repeat;
	animation: runner 750ms infinite;
}
